import React from 'react'
import { graphql } from 'gatsby'
import { BlogIndex } from '../../components'
 
const Article = ({ data, location }) => {
  return (
    <BlogIndex 
      location={location} 
      data={data} 
    />
  )
}

export const pageQuery = graphql`
  query BlogIndex {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "blog"}
        }
      }
      sort: {
        fields: [frontmatter___date], 
        order: [DESC]
      },
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            header
            tags
            author {
              frontmatter {
                authorName
                email
                linkedin
                twitter
              }
            }
            date(formatString: "MMMM DD, YYYY")
            relativeDate: date(fromNow: true)
            featuredpost
            featuredimage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          headings(depth: h2) {
            value
          }
          html
          fields {
            slug
          }
        }
      }
    }
    tags: allMarkdownRemark(
      filter: {
        frontmatter: {templateKey: {eq: "blog"}}
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            tags
          }
        }
      }
    }
  }
`

export default Article
